// Typography
$font-family-main: Verdana, '游ゴシック', YuGothic, 'Hiragino Kaku Gothic ProN', Meiryo, Osaka-mono, 'MS Gothic', monospace, sans-serif !important;
$font-family-headings: Verdana, '游ゴシック', YuGothic, 'Hiragino Kaku Gothic ProN', Meiryo, Osaka-mono, 'MS Gothic', monospace, sans-serif !important;
$font-family-logo: Verdana, '游ゴシック', YuGothic, 'Hiragino Kaku Gothic ProN', Meiryo, Osaka-mono, 'MS Gothic', monospace, sans-serif !important;
//$font-family-main: 'Source Sans Pro', Helvetica, Arial, sans-serif;
//$font-family-headings: 'Source Sans Pro', Helvetica, Arial, sans-serif;
//$font-family-logo: 'Source Sans Pro', Helvetica, Arial, sans-serif;
$font-size: 1.15em;   //original was 1.25em

// Padding
$padding-x-large: 23%;
$padding-large: 9%;
$padding-medium: 6%;
$padding-small: 4%;
$padding-x-small: 3%;
$title-padding: 0.5em;

// Mobile display
$xl-break: 2000px;
$l-break: 1400px;
$m-break: 1000px;
$break: 768px;
$sm-break: 576px;
$x-sm-break: 300px;

// Brand colours
$theme-color: #337ab7;
$brand-color: #fff;
$background-color: #fff;
$border-color: rgba(0, 0, 0, 0.1); // rgba recommended if using feature images

// Typography colours
$text-color: #262626;
$meta: #595959; //lowest gray for accessible color
$link-color: $theme-color;
$selection-color: #D4D4D4; // visible when highlighting text
$blockquote-color: #EEF7FA; // $background-color;

// Header
$header-background-color: $theme-color;  // Only used when no header image
$header-text-color: #fff;
$header-link-color: #383838;
$navbar-separator-opacity: 0;

// Feature image for articles
$feature-image-text-color: #fff;
$feature-image-size: cover; // options include "cover", "contain", "auto"

//Search colours
$link-shadow: transparentize($link-color, .6); //rgba(26, 188, 156, 0.6);
$text-shadow: #3f3f3f;
$search-color: #999;


/*  Syntax highlighting  */
// Syntax typography
$monospace: Monaco, Consolas, "Lucida Console", monospace !default;
$font-size-code: 1.0em !default; // ~12px  original was 0.75 > 0.86 > 1.0
$border-radius: 4px !default;

// base16 Material Theme
//$base00: #263238 !default; // Default Background
//$base01: #2E3C43 !default; // Lighter Background (Used for status bars)
//$base02: #314549 !default; // Selection Background
//$base03: #546E7A !default; // Comments, Invisibles, Line Highlighting
//$base04: #B2CCD6 !default; // Dark Foreground (Used for status bars)
//$base05: #EEFFFF !default; // Default Foreground, Caret, Delimiters, Operators
//$base06: #EEFFFF !default; // Light Foreground (Not often used)
//$base07: #FFFFFF !default; // Light Background (Not often used)
//$base08: #F07178 !default; // Variables, XML Tags, Markup Link Text, Markup Lists, Diff Deleted
//$base09: #F78C6C !default; // Integers, Boolean, Constants, XML Attributes, Markup Link Url
//$base0a: #FFCB6B !default; // Classes, Markup Bold, Search Text Background
//$base0b: #98C379 !default; // Strings, Inherited Class, Markup Code, Diff Inserted
//$base0c: #89DDFF !default; // Support, Regular Expressions, Escape Characters, Markup Quotes
//$base0d: #82AAFF !default; // Functions, Methods, Attribute IDs, Headings
//$base0e: #C792EA !default; // Keywords, Storage, Selector, Markup Italic, Diff Changed
//$base0f: #FF5370 !default; // Deprecated, Opening/Closing Embedded Language Tags e.g.


//$base00: #292D3E !default; 
//$base01: #444267 !default;
//$base02: #32374D !default;
//$base03: #676E95 !default;
//$base04: #8796B0 !default;
//$base05: #959DCB !default;
//$base06: #959DCB !default;
//$base07: #FFFFFF !default;
//$base08: #F07178 !default;
//$base09: #F78C6C !default;
//$base0a: #FFCB6B !default;
//$base0b: #C3E88D !default;
//$base0c: #89DDFF !default;
//$base0d: #82AAFF !default;
//$base0e: #C792EA !default;
//$base0f: #FF5370 !default;

$base00: rgb(248, 246, 233) !default;
$base01: #E7EAEC !default;
$base02: #CCEAE7 !default;
$base03: #CCD7DA !default;
$base04: #8796B0 !default;
$base05: rgb(45, 71, 69) !default;
$base06: rgb(69, 110, 106) !default;
$base07: #FFFFFF !default;
$base08: #FF5370 !default;
$base09: #F76D47 !default;
$base0a: #FFB62C !default;
$base0b: #91B859 !default;
$base0c: rgb(12, 41, 43) !default;
$base0d: #6182B8 !default;
$base0e: rgb(64, 38, 134) !default;
$base0f: #E53935 !default;



